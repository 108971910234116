@use "node_modules/@ef-global/web-ui/scss/settings/mixins/breakpoints";

.heroVideo {
  width: 100%;
  overflow: hidden;

  &:not(.autoplay) {
    cursor: pointer;
  }

  @include breakpoints.breakpoint(l) {
    &.rounded {
      border-radius: 16px;
    }
  }

  @include breakpoints.breakpoint(2200px) {
    max-width: 2200px;
    margin: 0 auto;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
}

.videoWrap {
  position: relative;
}

.heroModal {
  padding: 0;
  background: none;
}


.videoOverlay {
  pointer-events: none;
  position: absolute;
  top: 12%;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    @include breakpoints.breakpoint(s only) {
      font-size: 40px;
      line-height: 48px;
    }
  }
}

.videoPlayButton {
  cursor: pointer;
  width: 96px;
  height: 96px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  box-shadow: 0 8px 24px rgba(#191919, 0.3);

  @include breakpoints.breakpoint(l) {
    margin-top: 24px;
    width: 102px;
    height: 102px;
  }
}

.smallVideoPlayButton {
  width: 32px;
  height: 32px;
  margin-top: 16px;

  @include breakpoints.breakpoint(l) {
    width: 48px;
    height: 48px;
  }
}

.centerVideoButton {
  top: 50%;
  transform: translateY(-50%);
}
